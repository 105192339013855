.bnr_area {
  position: relative;
  z-index: 1;
  min-height: 430px;
}
.bnr_area .bnr_inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 430px;
  background: #f9f9ff;
  z-index: 1;
}
.bnr_area .bnr_inner .bnr_content {
  margin-top: 70px;
}
.bnr_area .bnr_inner .bnr_content h2 {
  color: #222222;
  font-size: 36px;
  font-family: "Heebo", sans-serif;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: bold;
}
.bnr_area .bnr_inner .bnr_content .page_link a {
  font-size: 14px;
  color: #777777;
  font-family: "Roboto", sans-serif;
  margin-right: 32px;
  position: relative;
}
.bnr_area .bnr_inner .bnr_content .page_link a:before {
  content: "->";
  font-family: "Linearicons-Free";
  font-size: 14px;
  position: absolute;
  right: -25px;
  top: 54%;
  transform: translateY(-50%);
}
.bnr_area .bnr_inner .bnr_content .page_link a:last-child {
  margin-right: 0px;
}
.bnr_area .bnr_inner .bnr_content .page_link a:last-child:before {
  display: none;
}

.feature_area {
  background: #f9f9ff;
}
.feature_area.feature_tow {
  background: #fff;
}
.feature_area.feature_tow .feature_item {
  background: #f9f9ff;
}
.feature_area.feature_tow .feature_item:hover {
  background: #fff;
}

.feature_inner {
  margin-bottom: -30px;
}

.feature_item {
  padding: 50px 35px;
  border-radius: 10px;
  transition: all 300ms linear 0s;
  background: #fff;
  margin-bottom: 30px;
}
.feature_item i,
.feature_item .icon {
  margin-bottom: 35px;
  display: block;
}
.feature_item i:before,
.feature_item .icon:before {
  margin-left: 0px;
  font-size: 60px;
  color: #e1e1e1;
  line-height: 60px;
}
.feature_item h4 {
  color: #222222;
  font-size: 21px;
  font-family: "Heebo", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.feature_item p {
  margin-bottom: 0px;
}
.feature_item .main_btn {
  padding: 0px 30px;
  line-height: 38px;
}
.feature_item:hover {
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  border-color: #fff;
}
.feature_item:hover i:before,
.feature_item:hover .icon:before {
  background: linear-gradient(to right, #8490ff 0%, #62bdfc 70%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home_bnr_area {
  z-index: 1;
  background-size: cover;
}
.home_bnr_area .bnr_inner {
  width: 100%;
}
.home_bnr_area .bnr_inner .home_left_img {
  padding-top: 195px;
}
.home_bnr_area .bnr_inner .col-lg-6 {
  vertical-align: middle;
  align-self: center;
}
.home_bnr_area .bnr_inner .bnr_content {
  text-align: left;
  color: #222222;
}
.home_bnr_area .bnr_inner .bnr_content h5 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  margin-bottom: 20px;
}
.home_bnr_area .bnr_inner .bnr_content h2 {
  margin-top: 0px;
  font-size: 48px;
  font-weight: bold;
  font-family: "Heebo", sans-serif;
  line-height: 54px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.home_bnr_area .bnr_inner .bnr_content p {
  color: #777777;
  font-family: "Roboto", sans-serif;
  max-width: 680px;
  font-size: 14px;
  line-height: 26px;
  margin: 0px auto 45px;
}
.home_bnr_area .bnr_inner .bnr_content a {
  font-size: 20px;
  color: #222222;
  margin-left: 8px;
  margin-right: 4px;
}
.home_bnr_area .bnr_inner .bnr_content .bnr_map_img {
  text-align: right;
}

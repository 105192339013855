@charset "UTF-8";

@import url("https://fonts.googleapis.com/css?family=Heebo:300,400,500,700|Roboto:300,400,500,700");
.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

.row.m0 {
  margin: 0px;
}

body {
  line-height: 26px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #777777 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Heebo", sans-serif;
  font-weight: bold;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.p_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.pad_top {
  padding-top: 120px;
}

.pad_btm {
  padding-bottom: 120px;
}

.mt-25 {
  margin-top: 25px;
}

.p0 {
  padding-left: 0px;
  padding-right: 0px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1620px) {
  .box_1620 {
    max-width: 1650px;
    margin: auto;
  }
}
.white_bg {
  background: #fff !important;
}

.main_title {
  text-align: center;
  margin-bottom: 75px;
}
.main_title h2 {
  font-family: "Heebo", sans-serif;
  font-size: 36px;
  color: #222222;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.main_title p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 26px;
  color: #777777;
  margin-bottom: 0px;
  max-width: 570px;
  margin: auto;
}
.main_title.white h2 {
  color: #fff;
}
.main_title.white p {
  color: #fff;
  opacity: 0.6;
}

/* Markdown code highight.js */
@import url("https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.3.1/styles/github-dark-dimmed.min.css");
